import './App.css'

import ErrorBoundary from 'components/ErrorBoundary'
import Loader from 'components/Icons/LoadingSpinner'
import { IpfsSubpathRedirect } from 'components/IpfsSubpathRedirect'
import NavBar from 'components/NavBar'
import { lazy, Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import DarkModeQueryParamReader from 'theme/components/DarkModeQueryParamReader'
import { flexRowNoWrap } from 'theme/styles'
import { Z_INDEX } from 'theme/zIndex'

import Ethereum from '../assets/img/cryptocurrency/ethereum.svg'
import AccordionArrow from '../assets/img/svg/arrow.svg'
import Footer from './Footer'
import { RouteDefinition, routes, useRouterConfig } from './RouteDefinitions'

const AppChrome = lazy(() => import('./AppChrome'))

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh);
  padding: ${({ theme }) => theme.navHeight}px 0px 5rem 0px;
  align-items: center;
  flex: 1;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    min-height: 100vh;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    min-height: 100vh;
  }
`

const MobileBottomBar = styled.div`
  z-index: ${Z_INDEX.sticky};
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  width: calc(100vw - 16px);
  justify-content: space-between;
  padding: 0px 4px;
  height: ${({ theme }) => theme.mobileBottomBarHeight}px;
  background: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  margin: 8px;
  border-radius: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const HeaderWrapper = styled.div<{ transparent?: boolean; scrollY: number }>`
  ${flexRowNoWrap};
  background-color: ${({ theme, transparent }) => !transparent && theme.surface1};
  border-bottom: ${({ theme, transparent }) => !transparent && `1px solid ${theme.surface3}`};
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX.dropdown};

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    top: 0;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    top: 0;
  }
`

function PoweredBy() {
  return (
    <div className="swap__protocol">
      Powered{' '}
      <a href="https://uniswap.org/" className="swap__protocol-link link-red">
        by Uniswap
      </a>{' '}
      Protocol V3
    </div>
  )
}
function InfoBlocks() {
  const listOfItems = [
    { name: 'Ethereum', shortName: 'ETH', logo: Ethereum, percent: '0.28%', value: '$2,909,56' },
    { name: 'Ethereum', shortName: 'ETH', logo: Ethereum, percent: '0.28%', value: '$2,909,56' },
    { name: 'Ethereum', shortName: 'ETH', logo: Ethereum, percent: '0.28%', value: '$2,909,56' },
    { name: 'Ethereum', shortName: 'ETH', logo: Ethereum, percent: '0.28%', value: '$2,909,56' },
    { name: 'Ethereum', shortName: 'ETH', logo: Ethereum, percent: '0.28%', value: '$2,909,56' },
    { name: 'Ethereum', shortName: 'ETH', logo: Ethereum, percent: '0.28%', value: '$2,909,56' },
  ]

  const handleAccordionClick = (event: any) => {
    const target = event.target
    const item = target.closest('.accordion__item')
    item.classList.toggle('active')
  }

  return (
    <div className="main-info">
      <div className="exchange-provide">
        <div className="container container--mini">
          <div className="exchange-provide__inner">
            <div className="exchange-provide__columns">
              {/* <div className="exchange-provide__made">
                <p className="exchange-provide__made-label">Exchange made easier</p>

                <ul className="exchange-provide__made-list">
                  {listOfItems.map((item, index) => (
                    <li className="exchange-provide__made-item" key={index}>
                      <div className="exchange-provide__made-info">
                        <img
                          className="exchange-provide__made-icon"
                          width="30"
                          height="30"
                          src={item.logo}
                          alt="token icon"
                        />
                        <p className="exchange-provide__made-name">{item.name}</p>
                        <p className="exchange-provide__made-network">{item.shortName}</p>
                      </div>

                      <div className="exchange-provide__made-sums">
                        <p className="exchange-provide__made-sum exchange-provide__made-sum--green">{item.percent}</p>
                        <p className="exchange-provide__made-sum">{item.value}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div> */}

              <div className="exchange-provide__empty">
                <p className="exchange-provide__empty-text">
                  Provide liquidity to pools on the Uniswap protocol and earn commissions on swaps
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cards">
        <a href="#" className="main-info__item main-info__item--rwa">
          <p className="cards__name">RWA marketplace</p>
        </a>
        <a href="#" className="main-info__item main-info__item--sawa">
          <div className="cards__name">Bambaswap Expert</div>
        </a>
        <a href="#" className="main-info__item main-info__item--twitter">
          <div className="cards__name">Twitter</div>
        </a>
      </div>
      <section className="faq">
        <div className="container container--mini">
          <div className="faq__inner">
            <div className="faq__head">
              <h3 className="faq__title">Frequently asked questions</h3>
              <h4 className="faq__subtitle">The most popular questions</h4>
            </div>

            <div className="faq__accordion">
              <div className="accordion">
                <div onClick={(event) => handleAccordionClick(event)} className="accordion__item" data-accordion-item>
                  <div className="accordion__header">
                    <h4 className="accordion__header-title">Question</h4>
                    <div className="accordion__header-line">
                      <img src={AccordionArrow} alt="arrow" />
                    </div>
                  </div>
                  <div className="accordion__content">
                    <p className="accordion__text">
                      A blockchain (from the English block chain — a chain of blocks) is a continuous sequential chain
                      of blocks containing various kinds of information. At the same time, each block contains not only
                      its own information, but also information about all previous blocks (encrypted in the form of a
                      so-called "hash"). These blocks are stored on the computers of many independent users. Thus,
                      unlike conventional databases, it is almost impossible to change or delete information once added
                      to the blockchain.
                    </p>

                    <a className="accordion__link-video" href="#">
                      Watch the video
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.1378 10.5689L9.60498 7.30246C8.40816 6.70405 7 7.57434 7 8.91243V15.0875C7 16.4256 8.40816 17.2959 9.60498 16.6975L16.1378 13.4311C17.3171 12.8414 17.3171 11.1585 16.1378 10.5689Z"
                          fill="#848484"
                        />
                      </svg>
                    </a>
                  </div>
                </div>

                <div onClick={(event) => handleAccordionClick(event)} className="accordion__item" data-accordion-item>
                  <div className="accordion__header">
                    <h4 className="accordion__header-title">Question</h4>
                    <div className="accordion__header-line">
                      <img src={AccordionArrow} alt="arrow" />
                    </div>
                  </div>
                  <div className="accordion__content">
                    <p className="accordion__text">
                      A blockchain (from the English block chain — a chain of blocks) is a continuous sequential chain
                      of blocks containing various kinds of information. At the same time, each block contains not only
                      its own information, but also information about all previous blocks (encrypted in the form of a
                      so-called "hash"). These blocks are stored on the computers of many independent users. Thus,
                      unlike conventional databases, it is almost impossible to change or delete information once added
                      to the blockchain.
                    </p>

                    <a className="accordion__link-video" href="#">
                      Watch the video
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.1378 10.5689L9.60498 7.30246C8.40816 6.70405 7 7.57434 7 8.91243V15.0875C7 16.4256 8.40816 17.2959 9.60498 16.6975L16.1378 13.4311C17.3171 12.8414 17.3171 11.1585 16.1378 10.5689Z"
                          fill="#848484"
                        />
                      </svg>
                    </a>
                  </div>
                </div>

                <div onClick={(event) => handleAccordionClick(event)} className="accordion__item" data-accordion-item>
                  <div className="accordion__header">
                    <h4 className="accordion__header-title">Question</h4>
                    <div className="accordion__header-line">
                      <img src={AccordionArrow} alt="arrow" />
                    </div>
                  </div>
                  <div className="accordion__content">
                    <p className="accordion__text">
                      A blockchain (from the English block chain — a chain of blocks) is a continuous sequential chain
                      of blocks containing various kinds of information. At the same time, each block contains not only
                      its own information, but also information about all previous blocks (encrypted in the form of a
                      so-called "hash"). These blocks are stored on the computers of many independent users. Thus,
                      unlike conventional databases, it is almost impossible to change or delete information once added
                      to the blockchain.
                    </p>

                    <a className="accordion__link-video" href="#">
                      Watch the video
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.1378 10.5689L9.60498 7.30246C8.40816 6.70405 7 7.57434 7 8.91243V15.0875C7 16.4256 8.40816 17.2959 9.60498 16.6975L16.1378 13.4311C17.3171 12.8414 17.3171 11.1585 16.1378 10.5689Z"
                          fill="#848484"
                        />
                      </svg>
                    </a>
                  </div>
                </div>

                <div onClick={(event) => handleAccordionClick(event)} className="accordion__item" data-accordion-item>
                  <div className="accordion__header">
                    <h4 className="accordion__header-title">Question</h4>
                    <div className="accordion__header-line">
                      <img src={AccordionArrow} alt="arrow" />
                    </div>
                  </div>
                  <div className="accordion__content">
                    <p className="accordion__text">
                      A blockchain (from the English block chain — a chain of blocks) is a continuous sequential chain
                      of blocks containing various kinds of information. At the same time, each block contains not only
                      its own information, but also information about all previous blocks (encrypted in the form of a
                      so-called "hash"). These blocks are stored on the computers of many independent users. Thus,
                      unlike conventional databases, it is almost impossible to change or delete information once added
                      to the blockchain.
                    </p>

                    <a className="accordion__link-video" href="#">
                      Watch the video
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.1378 10.5689L9.60498 7.30246C8.40816 6.70405 7 7.57434 7 8.91243V15.0875C7 16.4256 8.40816 17.2959 9.60498 16.6975L16.1378 13.4311C17.3171 12.8414 17.3171 11.1585 16.1378 10.5689Z"
                          fill="#848484"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default function App() {
  const location = useLocation()
  const { pathname } = location
  const [scrollY, setScrollY] = useState(0)
  const scrolledState = scrollY > 0
  const routerConfig = useRouterConfig()

  useEffect(() => {
    window.scrollTo(0, 0)
    setScrollY(0)
  }, [pathname])

  useEffect(() => {
    const scrollListener = () => {
      setScrollY(window.scrollY)
    }
    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  }, [])

  // useEffect(() => {
  //   const handleUrlChange = () => {
  //     const url = new URL(window.location.href)
  //     const scheme = url.protocol
  //     if (scheme === 'wc:') {
  //       // Handle the WalletConnect URL here
  //       console.log('WalletConnect URL detected:', window.location.href)
  //       // You may need to extract parameters and use them
  //     }
  //   }

  //   window.addEventListener('popstate', handleUrlChange)
  //   handleUrlChange() // Initial check

  //   return () => {
  //     window.removeEventListener('popstate', handleUrlChange)
  //   }
  // }, [])

  const isHeaderTransparent = !scrolledState

  const blockedPaths = document.querySelector('meta[property="x:blocked-paths"]')?.getAttribute('content')?.split(',')
  const shouldBlockPath = blockedPaths?.includes(pathname) ?? false
  if (shouldBlockPath && pathname !== '/swap') {
    return <Navigate to="/swap" replace />
  }

  return (
    <ErrorBoundary>
      <IpfsSubpathRedirect />
      <DarkModeQueryParamReader />
      <HeaderWrapper transparent={isHeaderTransparent} scrollY={scrollY}>
        <NavBar blur={isHeaderTransparent} />
      </HeaderWrapper>
      <BodyWrapper>
        <Suspense>
          <AppChrome />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Routes>
            {routes.map((route: RouteDefinition) =>
              route.enabled(routerConfig) ? (
                <Route key={route.path} path={route.path} element={route.getElement(routerConfig)}>
                  {route.nestedPaths.map((nestedPath) => (
                    <Route path={nestedPath} key={`${route.path}/${nestedPath}`} />
                  ))}
                </Route>
              ) : null
            )}
          </Routes>
        </Suspense>
        {/* <PoweredBy /> */}
        {pathname === '/swap' && <InfoBlocks />}
      </BodyWrapper>
      <Footer />
      {/* <MobileBottomBar>
        <PageTabs />
      </MobileBottomBar> */}
    </ErrorBoundary>
  )
}
