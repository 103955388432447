import Logo from '../../assets/img/svg/logo.svg'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          <a className="footer__logo" href="#">
            <img className="footer__logo-img" width="172" height="38" src={Logo} alt="logo iocn" />
          </a>

          <nav className="footer__nav">
            <ul className="footer__list">
              <li className="footer__item">
                <a className="footer__link" href="#">
                  About project
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="https://lucem.fund" target="_blank" rel="noreferrer">
                  Participant of the Lucem Fund project
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  Swap Usage Rules
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  Listing of new tokens
                </a>
              </li>
            </ul>

            <ul className="footer__list">
              <li className="footer__item">
                <a className="footer__link" href="#">
                  Liquidity Provider
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  Road map
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  Fee
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  Pool
                </a>
              </li>
            </ul>

            <ul className="footer__list">
              <li className="footer__item">
                <a className="footer__link" href="#">
                  Partners
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  Term of Use
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  Contact of us
                </a>
              </li>
              <li className="footer__item">
                <a className="footer__link" href="#">
                  Contact to Sale
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  )
}

export default Footer
